.border-main {
    border-bottom: 3px solid $primary;
}

.border-main-after {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        background-color: $primary;
    }
}

@include media-breakpoint-up(lg) {
    .border-lg-left {
        border-left: 1px solid $border-color;
    }

    .border-big-lg-left {
        border-left: 2px solid $border-color;
    }
}

/**/

.u-border {
    border: 1px solid $gray-300 !important;
}

$directions: top, bottom, right, left;

@each $direction in $directions {
    .u-border-#{$direction} {
        border-#{$direction}: 1px solid $gray-300 !important;
    }
}

@each $key, $value in $container-max-widths {
    @include media-breakpoint-up($key)
    {
        @each $direction in $directions {
            .u-border-#{$key}-#{$direction} {
                border-#{$direction}: 1px solid $gray-300 !important;
            }
        }
    }
}
