.header-federal {
    border-bottom: 1px solid $border-color;
    
    font-size: rem(12);

    .nav-lang {
        font-size: rem(14);

        .nav-item + .nav-item {
            margin-left: 1rem;
        }

        .nav-item {
            &.active {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    height: 2px;
                    background-color: $primary;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .nav-link {
            padding: 0;
            color: $body-color;
        }
    }
}