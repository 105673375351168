.news-block {
    .news-block-date {
        font-size: rem(12);
    }

    .news-block-title {
        @include text-underline-border;
        letter-spacing: 1px;
        font-size: rem(24);
    }

    @include media-breakpoint-up(md) {
        &.news-block-big {
            .news-block-title {
                font-size: rem(38);
            }
        }
    }
}