.news-filter {
    background-color: $light;
    padding: 0.75rem 2rem;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    margin-top: 2rem;
    margin-bottom: 3rem;

    .custom-select {
        max-width: 100px;
    }
}