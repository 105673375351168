//
// Back to top
//

#backTop {
    align-items:center;
    background:$primary;
    border-radius:50%;
    color:white;
    display:flex;
    font-size:rem(12);
    height:rem(60);
    line-height:1.1;
    overflow:hidden;
    position:fixed;
    text-align:center;
    right:2rem;
    bottom:2rem;
    text-decoration:none;
    transition:all 0.35s;
    z-index:1000;
    width:rem(60);
    @include media-breakpoint-down(md){
        display:none !important;
    }
    @include media-breakpoint-up(lg){
        display:block;
    }
    > *{
        @include media-breakpoint-up(lg){
            position:absolute;
            left:50%;
            top:50%;
            transform:translate(-50%,-50%);
            transition:all 0.35s;
        }
    }
    span{
        display:block;
    }
    .fas {
        display:none;
        font-size:1.35rem;
        margin-top:rem(-65);
        @include media-breakpoint-up(lg){
            display:block;
        }
    }
    &:hover{
        text-decoration:none;
        span{
            @include media-breakpoint-up(lg){
                margin-top:rem(65);
            }
        }
        .fas{
            margin-top:0;
        }
    }
}