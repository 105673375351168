.navbar-brand {
    font-size: rem(12);
    color: $primary !important;
    font-weight: 600;

    @include media-breakpoint-down(md) {
        img {
            height: 40px;
        }
    }
}


header {
    // background-color: $white;
    margin-bottom: rem(50);

    .navbar-light .navbar-nav .nav-link {
        color: $body-color;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .navbar-nav {
        .nav-item {
            &.active {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 1rem;
                    right: 1rem;
                    bottom: 0;
                    height: 2px;
                    background-color: $primary;
                    margin-left: auto;
                    margin-right: auto;

                    @include media-breakpoint-down(md) {
                        right: auto;
                        bottom: 8px;
                        top: 8px;
                        margin-top: auto;
                        margin-bottom: auto;
                        width: 2px;
                        height: auto;
                        left: 4px;
                    }
                }
            }
        }
    }
}

.navbar {
    border-bottom: 1px solid $border-color;
    margin-bottom: 1.5rem;
}

.no-header-divider {
    .navbar {
        border-bottom: 0;
        margin-bottom: 0;
    }
}
