// Links
a {
  &[target="_blank"]:not(.no-icon),
  &[target="_new"]:not(.no-icon) {
    &:after{
      margin-left:$space-icon-link;
      @include faw-icon(\f35d);
      font-size: 80%;
      vertical-align: 1px;
    }
    @each $doc, $value in $icon-link {
      @include icon-link($doc, $value);
    }
  }
}

h1, .h1 {
  margin-bottom: rem(40);
}

.wysiwyg-content {
  h2, .h2 {
    margin-bottom: 1rem;
    // line-height: 1.5;

    &:not(:first-child) {
      margin-top: rem(40);
    }
  }

  h3, .h3 {
    margin-bottom: rem(16);
    color: $gray-600;

    &:not(:first-child) {
      margin-top: rem(35);
    }
  }

  h4, .h4 {
    margin-bottom: rem(16);
    color: $gray-600;
    font-weight: normal;

    &:not(:first-child) {
      margin-top: rem(20);
    }
  }

  .bg-light {
    a {
      color: $dark-primary;
    }
  }
}

ul, ol {
  padding-left: 1.2rem;
}

mark, .search-highlight {
  background-color: $light;
  display: inline;
}

.search-highlight + .search-highlight {
  margin-left: 6px;
}

.text-white {
  a {
    color: $white;
    @include hover-focus() {
      color: darken($white, $emphasized-link-hover-darken-percentage) !important;
    }
  }
}
