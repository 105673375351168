.search-hit {
    .search-hit-title {
        @include text-underline-border;
    }

    .search-hit-subtitle {
        margin-top: 0.75rem;
        font-size: rem(15);
    }

    .search-hit-date {
        font-size: rem(12);
        margin-bottom: 0.5rem;

        & + .search-hit-date {
            margin-left: 1rem;
        }
    }

    .search-hit-intro {}
}