.box-shadow {
    box-shadow: $box-shadow;
}

.box-shadow-hover {
    transition: $transition-base;
    &:hover {
        box-shadow: $box-shadow;
    }

    @include media-breakpoint-down(sm) {
        box-shadow: $box-shadow;
    }
}