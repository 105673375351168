@use "sass:math";

@media print {

  body {
    background-color: #fff;
    line-height: 1.2;
  }

  .container {
    width: auto;
    max-width: 100%;
    padding: 0;
  }

  @for $i from 1 through 12 {
    .col-print-#{$i} {
      width: #{percentage(math.div(round($i*8.33),100))};
      float: left;
    }
  }

  .page-footer, .page-footer-space {
    background: #ffffff;
  }
}