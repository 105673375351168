.font-weight-semi {
    font-weight: 600;
}

.text-xs {
    font-size: $font-size-xs;
}
.text-sm {
    font-size: $font-size-sm;
}
.text-base {
    font-size: $font-size-base;
}
.text-lg {
    font-size: $font-size-lg;
}
.text-big {
    font-size: $font-size-big;
}
.text-xl {
    font-size: $font-size-xl;
}
.text-xxl {
    font-size: $font-size-xxl;
}

.text-underline {
    text-decoration: underline;
}

.font-primary {
    font-family: $font-family-primary;
}

.font-secondary {
    font-family: $font-family-secondary;
}