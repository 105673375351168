.pagination {
    .page-item {
        &:last-child {
            .page-link {
                border-top-right-radius: rem(25);
                border-bottom-right-radius: rem(25);
            }
        }

        &:first-child {
            .page-link {
                border-top-left-radius: rem(25);
                border-bottom-left-radius: rem(25);
            }
        }
    }
}