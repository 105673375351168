.list-check, .list-times, .list-chevron, .list-valid {
    padding-left: 1.5rem;
    list-style-type: none;

    li {
        position: relative;

        &:before {
            position: absolute;
            top: 4px;
            left: -1rem;
        }

        & + li {
            margin-top: rem(10);
        }
    }
}

.list-check {
    li {
        &:before {
            @include faw-icon(\f00c);
            color: $primary;
            font-size: 0.8rem;
        }
    }
}

.list-times {
    li {
        &:before {
            @include faw-icon(\f00d);
            color: $primary;
        }
    }
}

.list-chevron {
    li {
        &:before {
            @include faw-icon(\f105);
            color: $primary;
        }
    }
}

.list-valid {
    padding-left: 2rem;

    li {
        &:before {
            content: '';
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 22px;
            height: 20px;
            left: -2rem;
        }

        &.valid {
            &:before {
                background-image: url(../static/images/icon_valid.svg);
            }
        }

        &.invalid {
            &:before {
                background-image: url(../static/images/icon_invalid.svg);
            }
        }

        & + li {
            margin-top: 1.25rem;
        }
    }
}

dl {
    font-size: rem(14);
    
    &.light {
        dt {
            font-weight: normal;
            color: $gray-600;
        }
    }
}