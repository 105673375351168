.error-404 {
    background: url(../static/images/illu_pomme_404.svg) no-repeat center 350px / 880px 700px;
    background-size: 120%;
    background-position: center 120%;
    padding-bottom: 3rem;
    @include media-breakpoint-up(md) {
        background-position-y: 350px;
        background-size: 880px 700px;
        height: 100vh;
        display: flex;
        align-items: center;
        main {
            width: 100%;
        }
    }
    h1 {
        font-size: rem(120);
        line-height: 1;
        @include media-breakpoint-up(md) {
            font-size: rem(220);
        }
    }
    h2 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        @include media-breakpoint-up(md) {
            font-size: $h1-font-size;
            margin-bottom: 2.5rem;
        }
    }
}