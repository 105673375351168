.heading-image {
    height: 270px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.content-body {
    padding-top: rem(20);
    padding-bottom: rem(20);

    h1 {
        margin-bottom: rem(50);
    }
}

.chapo {
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    font-size: rem(22);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 10px;
        background-image: linear-gradient(to bottom, $primary, $pink);
    }

    ul {
        padding-left: 1.2rem;

        li:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    .card-footer {
        padding-top: 0;
    }
}

.wysiwyg-content {
    ul {
        list-style: none;
        padding-left: 1.2rem;

        li {
            position: relative;
            margin-bottom: 1rem;

            &:before {
                content: "\2022";
                color: $primary;
                font-size: 1.75rem;
                display: block;
                width: 1rem;
                left: -1.2rem;
                top: -0.5rem;
                position: absolute;
            }
        }

        ul {
            margin-top: 1rem;

            li {
                margin-bottom: rem(10);

                &:before {
                    content: "\00BA";
                    font-size: 1rem;
                    top: 0.2rem;
                }
            }
        }
    }

    .bg-light {
        ul li:before {
            color: $dark-primary;
        }
    }
}

.formulaire-item {
    display: flex;
    background-color: $light;
    padding: 0.5rem 1.5rem;
    align-items: center;
    position: relative;
    flex-wrap: wrap;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: $primary;
        width: 2px;
    }

    a {
        display: inline-block;

        i {
            font-size: 2rem;
        }

        & + a {
            margin-left: 1rem;
        }
    }

    & + .formulaire-item {
        border-top: 1px solid $border-color;
    }

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;

        .formulaire-documents {
            flex: 0 0 100px;
            text-align: right;
        }
    }
}
