.close-icon {
    position: relative;
    height: rem(30);
    width: rem(30);
    transform: rotate(45deg);
    border: 0;
    background: none;

    span {
        display: block;
        background: $body-color;
        border-radius: 2px;
        position: absolute;

        &:nth-child(1) {
            height: 80%;
            width: .15rem;
            top: 10%;
            left: calc(50% - .15rem/2);
        }

        &:nth-child(2) {
            width: 80%;
            height: .15rem;
            left: 10%;
            top: calc(50% - .15rem/2);
        } 
    }
}