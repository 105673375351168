.s-staging {
  &:before {
    content: "staging";
    position: fixed;
    display: block;
    transform: rotate(-45deg);
    opacity: .2;
    font-size: 20em;
    color: gray;
    z-index: -1;
  }
}
