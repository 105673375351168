.swiper {
    // overflow: hidden;
}

.swiper-button-prev, .swiper-button-next {
    position: relative;
    right: auto;
    left: auto;
    bottom: auto;
    top: auto;
    display: inline-block;
    width: rem(32);
    height: rem(32);
    background-color: $primary;
    border-radius: 50%;
    margin: 0 1rem;

    &:after {
        font-size: 1rem;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $white;
    }

    &.swiper-button-disabled {
        background-color: $light;
        opacity: 1;

        &:after {
            color: $primary;
        }
    }
}

.swiper-button-prev {
    &:after {
        left: calc(50% - 1px);
    }
}

.swiper-button-next {
    &:after {
        left: calc(50% + 1px);
    }
}

.swiper-navigation {
    text-align: center;
}

.swiper-navigation-light {
    .swiper-button-prev, .swiper-button-next {
        &.swiper-button-disabled {
            background-color: $white;
        }
    }
}