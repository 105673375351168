.card {
    .card-title {
        font-size: rem(24);
    }

    .card-img-logo {
        flex-shrink: 1;
        width: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(25);
    }
}