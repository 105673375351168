#searchModal {
    .modal-header {
        border: 0;

        .close {
            color: $white;
            text-shadow: none;
            opacity: 1;
            font-size: 1.25rem;
            margin: 0;
        }
    }

    .modal-dialog-centered {
        align-items: stretch;
    }

    .modal-content {
        background-color: $light;
        border: 0;
    }

    .modal-body {
        flex: 0;
    }
    .navbar-brand {
        color: darken($primary,3) !important;
    }

    .search-group {
        .form-control {
            color: $body-color;
            border-color: $primary;

            &::placeholder {
                color: $body-color !important;
            }
            &:-ms-input-placeholder {
                color: $body-color !important;
            }
            &::-ms-input-placeholder {
                color: $body-color !important;
            }
        }
        .btn {
            color: darken($primary,3) !important;
            border-bottom: 1px solid $primary;
        }
    }
}

.modal-backdrop {
    background-color: $light;
    
    &.show {
        opacity: 1;
    }
}