.btn {
    &.no-hover {
        cursor: auto !important;

        &:hover, &:active, &:focus {
            color: inherit !important;
            background-color: inherit !important;
        }

        &.btn-outline-primary {
            color: $primary !important;
        }
    }
}

.btn-round {
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    position: relative;

    &.btn-lg {
        width: 54px;
        height: 54px;
        line-height: 54px;
    }

    > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.btn-pulse-hover {
    &:hover {
        box-shadow: 0 0 0 15px rgba(44,127,159,0.08);
    }
}
.btn-light.text-primary {
    color: darken($primary,3) !important;
}

.c-rss-btn {
  background-color:$rss;
  border:none;
  color:white;
  padding: 4px 8px 2px;
  font-size: 12px;
  border-radius: 3px;

  &:hover {
    background-color:darken($rss, 10%);
    color: white;
  }

  &:visited,
  &:focus,
  &:active {
    background-color: darken($rss, 20%);
    color: white;
  }

  &:visited {
    background-color:darken($success, 10%);
    &:hover {
      background-color:darken($success, 20%);
    }
  }
}
