.quick-access {
    .card {
        box-shadow: 0px 10px 20px #2C7F9F33;
        border: 0;
        height: 100%;

        .card-text > *:last-child {
            margin-bottom: 0;
        }
    }
}