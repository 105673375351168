.modal, #footer {
    .form-control {
        border-left: 0;
        border-top: 0;
        border-right: 0;
        padding-left: 0;
        border-radius: 0;
    }

    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-append > .btn {
        border-radius: 0;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid $input-border-color;
        padding-left: 0;
        padding-right: 0;
    }
}

.form-group {
    &.disabled {
        opacity: 0.25;

        label, .custom-select {
            pointer-events: none;
        }
    }
}