// .icon-search {
//     display: inline-block;
//     vertical-align: middle;
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     background-image: url(../../static/images/);
// }

[class*="icon-"] {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


.icon-valid, .icon-valid-small {
    background-image: url(../static/images/icon_valid.svg);
}

.icon-valid {
    width: rem(35);
    height: rem(35);
}

.icon-valid-small {
    width: rem(20);
    height: rem(20);
}

.icon-invalid, .icon-invalid-small {
    background-image: url(../static/images/icon_invalid.svg);
}

.icon-invalid {
    width: rem(35);
    height: rem(35);
}

.icon-invalid-small {
    width: rem(20);
    height: rem(20);
}

.icon-pending-validation, .icon-pending-validation-small {
    background-image: url(../static/images/icon_pending_validation.svg);
}

.icon-pending-validation {
    width: rem(35);
    height: rem(35);
}

.icon-pending-validation-small {
    width: rem(20);
    height: rem(20);
}