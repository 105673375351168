@include media-breakpoint-down(sm) {
  [data-aos] {
    @for $i from 3 through 60 {
      body[data-aos-delay='#{$i * 50}'] &,
      &[data-aos][data-aos-delay='#{$i * 50}'], .tataaaa {
        transition-delay: 0;
        &.aos-animate {
          transition-delay: 100ms;
        }
      }
    }
  }
}
// disable animations
@media (prefers-reduced-motion: reduce) {
  body [data-aos] {
    opacity: 1 !important;
    transform: none !important;
  }
}