.bg-outbound-left, .bg-outbound-right {
    position: relative;

    > * {
        z-index: 1;
        position: relative;
    }

    &::before, &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        background-color: inherit;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }
}

.bg-outbound-left {
    &::before {
        right: 0;
        left: -1000px;
    }
    &::after {
        left: 0;
        right: -15px;
    }
}
.bg-outbound-right {
    &::before {
        right: -1000px;
        left: 0;
    }
    &::after {
        right: 0;
        left: -15px;
    }
}