@include media-breakpoint-up(sm) {
    #dropdownMenuAction {
        min-width: 310px;
    }
    
    #dropdownMenuProfession {
        min-width: 420px;
    }
}

.dropdown-toggle {
    &:after {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
}

#dropdownMenuAction {
    text-align: left;
}

#dropdownMenuProfession {
    text-align: left;
}