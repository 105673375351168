.language-selection {
    height: 100vh;
    background: url(../static/images/illu_pomme_langue.svg) no-repeat center 50px / 700px 955px;
    display: flex;
    align-items: center;

    main {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        background-size: cover;
        background-position: center -50px;
    }
}