.collapse-item {
    background-color: $light;
    padding: 0.75rem;
    border-radius: $border-radius;

    &:not(:first-child) {
        margin-top: 1.5rem;
    }

    p:last-child,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        margin-bottom: 0 !important;
    }

    [data-toggle="collapse"] {
        position: relative;
        padding-left: 2rem;
        display: block;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 5px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url('../static/images/icon close.svg');
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
        }

        &[aria-expanded="true"] {
            &:before {
                transform: rotate(0deg);
            }
        }
    }

    h2, .h2 {
        [data-toggle="collapse"] {
            &:before {
                top: 6px;
            }
        }
    }

    h3, .h3 {
        [data-toggle="collapse"] {
            &:before {
                top: 3px;
            }
        }
    }

    .collapse-body {
        padding: 1.25rem 1rem 0.75rem 2rem;
    }

    & + .collapse-item {
        margin-top: 1.25rem;
    }
}

// same padding
.non-collapse-item {
    padding: 1.25rem 0.75rem 0.75rem 2rem;
}