.homepage {
    header {
        margin-bottom: 0;
    }

    .navbar {
        border-bottom: 0;
        margin-bottom: 0;
    }
}

.target-choice {
    background-color: $light;
    padding-top: rem(100);
    padding-bottom: rem(100);

    @include media-breakpoint-down(md) {
        padding-top: rem(50);
        padding-bottom: rem(50);
    }
}

@include media-breakpoint-up(md) {
    .target-choice {
        background: url('../static/images/home_banner.jpg') no-repeat center / cover;
    }
}