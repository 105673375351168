.table {
  thead {
    background-color: $light;

    th {
      border-width: 0;
      vertical-align: middle;
      font-weight: normal;
      text-transform: uppercase;
      font-size: rem(14);
    }
  }
  tbody {
    td {
      border-bottom: 1px solid $border-color;
      border-top: 0;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}

.table-sm {
  tbody {
    td {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

table caption {
  caption-side: top;
}
