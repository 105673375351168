// IE10 and IE11   (Method 2)
// ---------------------------------
@media all and (-ms-high-contrast:none){
  .homepage #offer .circle {
    fill: #8b79b5 !important;
    opacity: .5;
  }
}
// ---------------------------------

/*----MS Edge Browser CSS Start----*/
@supports (-ms-ime-align:auto) and (display:-ms-grid){
  ul.nav,
  ul.list-unstyled,
  ul.dropdown-menu {
    list-style: none;
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) !important;
    list-style-type: none;
  }
  .homepage #offer .circle {
    fill: #8b79b5 !important;
    opacity: .5;
  }
}
/*----MS Edge Browser CSS End----*/

// Hack for remove text-decoration on speudo class
// https://stackoverflow.com/questions/27018420/ie-remove-underline-on-pseudo-element 
a {
  &:after, &:before{
    text-decoration:underline;
  }
  &:after, &:before{
    text-decoration:none;
  }
}