footer {
    margin-top: rem(50);
    padding-top: rem(40);
    padding-bottom: rem(40);
    border-top: 1px solid $border-color;

    hr {
        margin-top: rem(50);
        margin-bottom: rem(35);
    }

    .nav-item {
        margin-bottom: 0.5rem;
    }

    .nav-link, a {
        color: $body-color;
        @include text-underline-border;
        padding: 0;
    }

    h2, .h2 {
        font-size: rem(24);
    }

    @include media-breakpoint-down(md) {
        h2, .h2 {
            font-size: rem(20);
        }
    }
}
