// @include fontfaceWoff("Heebo", "#{$fonts-path}/heebo-v7-latin-regular", normal);
// @include fontfaceWoff("Heebo", "#{$fonts-path}/heebo-v7-latin-500", 500);
// @include fontfaceWoff("Heebo", "#{$fonts-path}/heebo-v7-latin-700", 700);

// @include fontfaceWoff("Roboto", "#{$fonts-path}/roboto-regular-webfont", normal);
// @include fontfaceWoff("Roboto", "#{$fonts-path}/roboto-medium-webfont", 500);


@include fontfaceWoff("DMSerifDisplay", "#{$fonts-path}/DMSerifDisplay-Regular", normal);

@include fontfaceWoff("Open Sans", "#{$fonts-path}/open-sans-v18-latin-regular", normal);
@include fontfaceWoff("Open Sans", "#{$fonts-path}/open-sans-v18-latin-600", 600);

// @include fontfaceWoff("Raleway", "#{$fonts-path}/Raleway-Regular", normal);
// @include fontfaceWoff("Raleway", "#{$fonts-path}/Raleway-Medium", 500);
// @include fontfaceWoff("Raleway", "#{$fonts-path}/Raleway-SemiBold", 600);
// @include fontfaceWoff("Raleway", "#{$fonts-path}/Raleway-Bold", bold);

// @include fontfaceWoff("Source Sans Pro", "#{$fonts-path}/sourcesanspro-regular-webfont", normal);
// @include fontfaceWoff("Source Sans Pro", "#{$fonts-path}/sourcesanspro-semibold-webfont", 600);
// @include fontfaceWoff("Source Sans Pro", "#{$fonts-path}/sourcesanspro-bold-webfont", bold);

// @include fontfaceWoff("font-onem", "#{$fonts-path}/font-onem", normal);