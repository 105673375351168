.search-filter {
    background-color: $light;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    margin-top: 2rem;
    margin-bottom: 0.5rem;

    .form-control {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding-left: 0;
        border-color: $primary;
    }

    .btn {
        border-radius: 0;
        padding-right: 0;
        border-bottom: 1px solid $primary;
        color: darken($primary,3);
    }
}