.pro-filter {
    .dropdown {
        .custom-control-label {
            width: 100%;
        }
    }

    .dropdown-toggle, .dropdown-item {
        white-space: normal;
    }

    @include media-breakpoint-down(lg) {
        .dropdown {
            width: 100%;
        }

        .dropdown-toggle {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
        }
    }
}